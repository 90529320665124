<template>
    <div class="sponsor row justify-content-center pt-sm-3">        
        <div class="col-xl-10">
            <!-- search -->
            <form v-if="false" action="" class="sponsor__search mb-2 mb-sm-0" v-show="!isPagesLoading">
                <input type="text" class="form-control" placeholder="關鍵字搜尋" @keyup="Sch()" v-model="SSch">
            </form>

            <!-- card -->
            <div class="row">
                <!-- <div class="col-md-6">
                    <div class="card mb-4">
                        <div class="row">
                            <div class="col-md-5">
                                <img src="https://via.placeholder.com/400x300" class="w-100 mb-4 mb-md-0">
                            </div>
                            <div class="col-md-7 pl-md-0">
                                <div class="des__head">
                                    <h2 class="font-weight-bold mb-1">華南商業銀行</h2>
                                </div>
                                <div class="des__foot d-flex flex-column flex-md-row align-items-md-end">
                                    <ul class="flex-grow-1 list-unstyled font-weight-normal mb-0">
                                        <li>地址：新北市淡水區中正路28號</li>
                                        <li>電話：02-26219680</li>
                                    </ul>
                                </div>  
                            </div>
                        </div>
                    </div>                 
                </div> -->
                <!-- 手機版 小 -->
                <div class="col-md-6" v-for="(item, index) in Sponsor" :key="index" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                    <div class="card mb-3 mb-md-4">
                        <div class="row">
                            <div class="col-5 col-md-5 col-lg-4">
                                <img :src="item.Logo" class="w-100">
                            </div>
                            <div class="col-7 col-md-7 col-lg-8 pl-0 d-flex flex-column justify-content-center">
                                <div class="des__head">
                                    <h2 class="font-weight-bold mb-1">{{item.Title}}</h2>
                                </div>
                                <div class="des__foot d-flex flex-column flex-md-row align-items-md-end">
                                    <ul class="flex-grow-1 list-unstyled font-weight-normal mb-0">
                                        <li v-if="item.Address">地址：{{item.Address}}</li>
                                        <!-- <li>電話：{{item.Tel}}</li> -->
                                    </ul>
                                </div>  
                            </div>
                        </div>
                    </div>                 
                </div>                
            </div>
        </div>

        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>
    </div>
</template>

<script>
import PagesLoading from '@/components/PagesLoading.vue'
import _ from "lodash";

var SponsorArr=[];
export default {
    SponsorArr,
    components: {
        PagesLoading
    },
    data() {
        return {
            isPagesLoading: true,
            Sponsor:null,
            SSch:''
        }
    },
    /* Init */
    created() {
        this.ReadData();
    },
    methods: {

        // 加载到原型链上的lodash，在getSSch后取不到
        // 注意，这里debounce中的第一个参数，不能写成箭头函数，否则，取不到this
        getSSch: _.debounce(function () {
            // console.log('此处向后台发起请求:', this.SSch);
            this.Sponsor=SponsorArr.filter(item => (item.Title.indexOf(this.SSch)!=-1));
        }, 800),
        Sch:function(){
            this.getSSch()
        },
        ReadData:function(){
            var FD = new FormData();
            FD.append('mode', 'sct');
            FD.append('data', '{"class":"Sponsor","lang":"zh-TW"}');
            //FD.append('data', '{"class":"Sponsor","lang":"zh-TW","sw":1}');
            this.axios({
                method: 'post',
                url: 'RApi',
                data: FD,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                SponsorArr=response.data.data;
                this.Sponsor=response.data.data;
                this.isPagesLoading = false;
            })
            .catch(function (error) {
                console.log(error);
            }); 
        }
    }
}
</script>